import styled from 'styled-components';
import { BodyStandard } from '../Typography/Typography.styles';

interface AttachmentsStyledProps {
  $noAttachments?: boolean;
}

interface AttachmentsNewBlockProps {
  disabled?: boolean;
}

export const AttachmentsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  column-gap: 20px;
  row-gap: 20px;
  padding: 10px 0 20px 0;
`;

export const AttachmentsNewRow = styled.label<AttachmentsNewBlockProps>`
  height: 42px;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 4px;
  cursor: pointer;
  ${BodyStandard};
  color: ${(props) => props.theme.colors.black};
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
  input {
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
    opacity: 0;
    pointer-events: none;
  }
  span {
    margin-left: 19px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  :focus-within {
    outline: 2px solid;
  }
`;

export const AttachmentsStyled = styled.div<AttachmentsStyledProps>`
  padding: 10px;
  background-color: ${(props) =>
    props.$noAttachments ? 'transparent' : props.theme.colors.gray50};
  border-radius: 12px;
  box-sizing: border-box;
  border: ${(props) =>
    props.$noAttachments ? `1px solid ${props.theme.colors.gray400}` : 'none'};
  ${AttachmentsNewRow} {
    margin-top: ${(props) => (props.$noAttachments ? 0 : '40px')};
  }
`;
