import type { ChangeEvent } from 'react';
import { useCallback, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Hooks
import useFileUpload from '../../hooks/useFileUpload';
import PlusIcon from '../icons/PlusIcon';

// Styles
import {
  AttachmentsStyled,
  AttachmentsList,
  AttachmentsNewRow,
} from './Attachments.styles';

// Components
import AttachmentsMedia, { AttachmentType } from './AttachmentsMedia';

export interface AttachmentEditProps {
  title: string;
  description: string;
}

export interface Attachment {
  type: AttachmentType;
  filePath?: string;
  storageLocation?: string;
  title: string;
  description: string;
  file?: File;
}

export type InternalAsset = Attachment & { id: string };

export interface AttachmentsProps {
  onAttachmentsChange?: (attachments: Attachment[]) => void;
  defaultAttachments?: Attachment[];
}

const Attachments = ({
  defaultAttachments,
  onAttachmentsChange,
}: AttachmentsProps) => {
  const [attachments, setAttachments] = useState<InternalAsset[]>([]);
  const [uploadingAttachments, setLoading] = useState<string[]>([]);

  const { upload, uploading } = useFileUpload();

  const emitAttachments = useCallback(
    (attachments: InternalAsset[]) => {
      const attachmentsToEmit = attachments.map(
        (attachment: InternalAsset) => ({
          type: attachment.type,
          filePath: attachment.filePath,
          title: attachment.title,
          description: attachment.description,
          storageLocation: attachment.storageLocation,
        }),
      );
      if (onAttachmentsChange) {
        onAttachmentsChange(attachmentsToEmit);
      }
    },
    [onAttachmentsChange],
  );

  const uploadFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const files = Array.from(e.target.files ? e.target.files : []);
      const newAttachments = files.map(
        (file: File): [InternalAsset, Promise<void>] => {
          const id = uuidv4();
          const attachment: InternalAsset = {
            id,
            filePath: '',
            type: AttachmentType.PHOTO,
            title: '',
            description: '',
            file,
          };
          const promise = async () => {
            const res = await upload(file);
            // add new data to attachment
            Object.assign(attachment, {
              filePath: res.filePath,
              storageLocation: res.storageLocation,
              title: res.originalFileName,
              type: res.type,
            });
          };
          return [attachment, promise()];
        },
      );
      const allAttachments = [
        ...attachments,
        ...newAttachments.map(([a]) => a),
      ];
      // track loading on newly added assets
      setLoading(newAttachments.map(([a]) => a.id));
      // set all attachments so they show UI
      setAttachments(allAttachments);
      // wait for all new attachments to upload
      await Promise.all(newAttachments.map(([, p]) => p));
      // once done, re-emit the events
      emitAttachments(allAttachments);
      setAttachments(allAttachments);
    },

    [attachments, upload, emitAttachments],
  );

  const deleteMedia = useCallback(
    async (attachment: InternalAsset) => {
      const newAttachments = attachments.filter(
        (att) => att.id !== attachment.id,
      );
      emitAttachments(newAttachments);
      setAttachments(newAttachments);
    },
    [attachments, emitAttachments],
  );

  const editMedia = useCallback(
    async (attachment: InternalAsset, data: AttachmentEditProps) => {
      if (attachment.filePath) {
        const newAttachments = attachments.map((att: InternalAsset) => {
          if (att.id === attachment.id) {
            return {
              ...att,
              title: data.title,
              description: data.description,
            };
          }
          return att;
        });
        setAttachments(newAttachments);
        emitAttachments(newAttachments);
      }
    },
    [attachments, emitAttachments],
  );

  useEffect(() => {
    if (defaultAttachments && defaultAttachments.length > 0) {
      setAttachments(
        defaultAttachments.map((attach) => {
          return {
            id: uuidv4(),
            ...attach,
          };
        }),
      );
    }
  }, [defaultAttachments]);

  return (
    <AttachmentsStyled $noAttachments={attachments.length === 0}>
      {attachments.length > 0 && (
        <AttachmentsList>
          {attachments.map((attachment: InternalAsset, index: number) => (
            <AttachmentsMedia
              key={attachment.id || index}
              filePath={attachment.filePath}
              type={attachment.type}
              name={attachment.title}
              description={attachment.description}
              loading={uploadingAttachments.includes(attachment.id)}
              onDelete={() => deleteMedia(attachment)}
              onEdit={(data: AttachmentEditProps) =>
                editMedia(attachment, data)
              }
            />
          ))}
        </AttachmentsList>
      )}
      <AttachmentsNewRow htmlFor="attachment-input" disabled={uploading}>
        <PlusIcon />
        <span>Add Attachment</span>
        <input
          id="attachment-input"
          type="file"
          multiple
          onChange={uploadFile}
          tabIndex={0}
        />
      </AttachmentsNewRow>
    </AttachmentsStyled>
  );
};

export default Attachments;
