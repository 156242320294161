import styled from 'styled-components';
import Label from '../Label';
import { BodyLarge, BodyStandard } from '../Typography/Typography.styles';

export const BaseInput = styled.input<{ $hasIcon?: boolean }>`
  appearance: none;
  border: none;
  background: transparent;
  ${BodyLarge};
  height: 100%;
  padding: ${({ $hasIcon }) => ($hasIcon ? '0 50px 0 18px' : '0 18px')};
  border: none;
  outline: none;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  &:disabled {
    background: ${(props) => props.theme.colors.gray200};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.gray600};
  }
`;

export const IconContainer = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  height: 24px;
  width: 24px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
`;

export const InputLabel = styled(Label)``;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  background: ${(props) => props.theme.colors.white};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray400};
  position: relative;
  transition: border 0.2s ease;
`;

export const InputErrorsUl = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 60px;
`;

export const InputErrorsLi = styled.li`
  ${BodyStandard};
  color: ${(props) => props.theme.colors.alertRed};
`;

export const Container = styled.div<{ $error: boolean; $disabled: boolean }>`
  width: 100%;
  &:focus-within {
    svg {
      transition: all 0.2s ease;
      color: ${(props) => props.theme.colors.black};
    }
    ${InputWrapper} {
      border-color: ${(props) => props.theme.colors.black};
    }
  }
  ${({ $error, theme }) =>
    $error &&
    `
    && {
      ${InputWrapper} {
        border-color: ${theme.colors.alertRed};
      }
      svg {
        color: ${theme.colors.alertRed};
      }
    }
  `}
`;
