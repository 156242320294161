import type { SVGAttributes } from 'react';

const EyeIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.503 16.406c1.23 0 2.276-.43 3.135-1.292.86-.862 1.29-1.908 1.29-3.138S16.496 9.7 15.634 8.84c-.861-.86-1.907-1.29-3.138-1.29-1.23 0-2.276.432-3.135 1.293-.86.861-1.289 1.908-1.289 3.138s.43 2.276 1.292 3.135c.861.86 1.908 1.29 3.138 1.29zm-.009-1.51c-.812 0-1.5-.285-2.064-.853a2.829 2.829 0 01-.847-2.07c0-.812.285-1.5.853-2.064a2.829 2.829 0 012.07-.847c.812 0 1.5.284 2.064.853.565.568.847 1.258.847 2.07 0 .812-.284 1.5-.853 2.064a2.829 2.829 0 01-2.07.847zm.006 4.895c-2.535 0-4.826-.72-6.875-2.161-2.049-1.44-3.576-3.325-4.583-5.651 1.007-2.326 2.534-4.21 4.583-5.651 2.049-1.441 4.34-2.162 6.875-2.162s4.826.721 6.875 2.162c2.049 1.44 3.576 3.325 4.583 5.651-1.006 2.326-2.534 4.21-4.583 5.651-2.049 1.441-4.34 2.162-6.875 2.162zm-.004-1.562a10.48 10.48 0 005.798-1.706 11.07 11.07 0 004.024-4.544 11.08 11.08 0 00-4.02-4.544 10.459 10.459 0 00-5.793-1.706 10.48 10.48 0 00-5.8 1.706 11.254 11.254 0 00-4.049 4.544 11.266 11.266 0 004.045 4.544 10.459 10.459 0 005.795 1.706z"
      fill="currentColor"
    />
  </svg>
);

export default EyeIcon;
