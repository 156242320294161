import { ForwardRefRenderFunction, forwardRef } from 'react';
import BaseInput, { InputProps } from './BaseInput';
import SearchIcon from '../icons/SearchIcon';
import styled from 'styled-components';
import { LabelStandard } from '../Typography/Typography.styles';

const StyledBaseInput = styled(BaseInput)`
  & .Wreno_BaseInput--input-wrapper {
    border-radius: 8px;
    height: 28px;
    input {
      padding: 0 28px 0 8px;
      ${LabelStandard};
    }
    .Wreno_BaseInput--icon-container {
      right: 7px;
      height: 13px;
      width: 13px;
    }
  }
`;

const SearchInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref,
) => {
  const { type } = props;
  const icon = <SearchIcon height={13} width={13} />;

  return (
    <StyledBaseInput
      {...props}
      ref={ref}
      type={type}
      iconContainerProps={{
        className: 'Wreno_BaseInput--icon-container',
      }}
      icon={icon}
    />
  );
};

export default forwardRef<HTMLInputElement, InputProps>(SearchInput);
