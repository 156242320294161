import { ForwardRefRenderFunction, forwardRef, useState, useMemo } from 'react';
import EyeCrossedIcon from '../icons/EyeCrossedIcon';
import EyeIcon from '../icons/EyeIcon';
import BaseInput, { InputProps } from './BaseInput';

const PasswordInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref,
) => {
  const { type } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputType = showPassword ? 'text' : type;
  const icon = showPassword ? <EyeIcon /> : <EyeCrossedIcon />;

  const iconContainerProps = useMemo(
    () => ({
      onClick: () => setShowPassword((s) => !s),
      'data-testid': 'input-block__eye-icon',
    }),
    [],
  );

  return (
    <BaseInput
      {...props}
      ref={ref}
      type={inputType}
      iconContainerProps={iconContainerProps}
      icon={icon}
    />
  );
};

export default forwardRef<HTMLInputElement, InputProps>(PasswordInput);
