import styled, { css } from 'styled-components';
import CloseIcon from '../../icons/CloseIcon';
import TextArea from '../../TextArea';
import { BodySmall } from '../../Typography/Typography.styles';

interface AttachmentsMediaStyledProps {
  $active?: boolean;
  $loading?: boolean;
}

const AttachmentsMedia = css`
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: top;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.2s ease;
`;

export const AttachmentsMediaImage = styled.img`
  ${AttachmentsMedia};
`;

export const AttachmentsMediaVideo = styled.video`
  ${AttachmentsMedia};
`;

export const AttachmentsMediaAudio = styled.div`
  height: 100px;
`;

export const AttachmentsMediaName = styled.div`
  margin-top: 13px;
  font-weight: 600;
  ${BodySmall};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AttachmentsMediaOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AttachmentsMediaOverlayRow = css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  margin-left: 4px;
  svg {
    color: ${(props) => props.theme.colors.black};
  }
`;

export const AttachmentsMediaOverlayEdit = styled.div`
  ${AttachmentsMediaOverlayRow};
  color: ${(props) => props.theme.colors.machineGreen600};
`;

export const AttachmentsMediaOverlayDelete = styled.div`
  ${AttachmentsMediaOverlayRow};
  color: ${(props) => props.theme.colors.black};
`;

const AttachmentsMediaHover = css`
  background-color: ${(props) => props.theme.colors.gray50};
`;

const AttachmentsMediaLoading = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 157px;
`;

export const AttachmentsMediaStyled = styled.div<AttachmentsMediaStyledProps>`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  width: 100%;
  padding: 10px 10px 13px 10px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  position: relative;
  border: 1px solid transparent;
  &:hover {
    ${AttachmentsMediaHover};
  }
  ${(props) => props.$active && AttachmentsMediaHover}
  ${(props) => props.$active && `border: 1px solid #B5CDB9`};
  ${(props) => props.$loading && AttachmentsMediaLoading}
`;

export const AttachmentsMediaEditPopup = styled.div`
  position: absolute;
  top: 48px;
  left: 106px;
  width: 340px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 15px 30px rgba(19, 32, 19, 0.15);
  border-radius: 4px;
  z-index: 2;
  padding: 20px;
`;

export const AttachmentsMediaEditPopupHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
`;

export const AttachmentsMediaEditPopupHeadTitle = styled.div`
  color: ${(props) => props.theme.colors.machineGreen600};
  ${BodySmall};
  font-weight: 700;
  text-transform: uppercase;
`;

export const AttachmentsMediaEditPopupHeadCloseControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const AttachmentsMediaEditPopupHeadCloseIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  color: ${(props) => props.theme.colors.black};
  margin-right: 16px;
`;

export const AttachmentsMediaEditPopupTextArea = styled(TextArea)`
  margin-top: 15px;
  height: 130px;
`;
