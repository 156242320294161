import type { ChangeEvent, KeyboardEvent } from 'react';
import type { AttachmentEditProps } from '../Attachments';
import { useState, useRef, useCallback } from 'react';
import FocusLock from 'react-focus-lock';

// Hooks
import useOnClickOutside from '../../../hooks/useOnClickOutside';

// Icons
import CheckIcon from '../../icons/CheckIcon';
import EditIcon from '../../icons/EditIcon';
import CloseIcon from '../../icons/CloseIcon';

// Styles
import theme from '../../../themes/theme';
import {
  AttachmentsMediaAudio,
  AttachmentsMediaEditPopup,
  AttachmentsMediaEditPopupHead,
  AttachmentsMediaEditPopupHeadCloseControls,
  AttachmentsMediaEditPopupHeadCloseIcon,
  AttachmentsMediaEditPopupHeadTitle,
  AttachmentsMediaEditPopupTextArea,
  AttachmentsMediaImage,
  AttachmentsMediaName,
  AttachmentsMediaOverlay,
  AttachmentsMediaOverlayDelete,
  AttachmentsMediaOverlayEdit,
  AttachmentsMediaStyled,
  AttachmentsMediaVideo,
} from './AttachmentsMedia.styles';

// Components
import Input from '../../Input';
import Loader from '../../Loader';
import { keyHandler } from '../../../utils/accessibilityUtils';

export enum AttachmentType {
  PHOTO = 'PHOTO',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  UNKNOWN = 'UNKNOWN',
}

interface AttachmentsMediaProps {
  type: AttachmentType;
  filePath?: string;
  name?: string;
  description: string;
  loading?: boolean;
  onDelete?: () => void;
  onEdit?: (data: AttachmentEditProps) => void;
}

const AttachmentsMedia = ({
  filePath,
  type,
  name,
  description: defaultDescription,
  loading,
  onDelete,
  onEdit,
  ...otherProps
}: AttachmentsMediaProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [title, setTitle] = useState<string>(() => {
    const value = name?.split('.')?.[0];
    return value || '';
  });
  const [description, setDescription] = useState<string>(
    defaultDescription ?? '',
  );
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);

  const closeEditPopup = useCallback(() => {
    setShowEditPopup(false);
  }, []);

  useOnClickOutside(ref, closeEditPopup);

  const edit = useCallback(() => {
    setShowEditPopup(false);
    if (onEdit) {
      onEdit({
        title,
        description,
      });
    }
  }, [title, description, onEdit]);

  return (
    <AttachmentsMediaStyled
      $active={showEditPopup}
      ref={ref}
      $loading={loading}
      data-testid="attachment"
    >
      {loading && <Loader inline />}
      {!loading && (
        <>
          {type === AttachmentType.PHOTO && (
            <AttachmentsMediaImage src={filePath} alt="" {...otherProps} />
          )}
          {type === AttachmentType.VIDEO && (
            <AttachmentsMediaVideo src={filePath} {...otherProps} />
          )}
          {type === AttachmentType.AUDIO && <AttachmentsMediaAudio />}
          {name && <AttachmentsMediaName>{name}</AttachmentsMediaName>}
          <AttachmentsMediaOverlay>
            {onEdit && (
              <AttachmentsMediaOverlayEdit
                onClick={() => setShowEditPopup(true)}
                onKeyDown={(e: KeyboardEvent) =>
                  keyHandler({
                    keyEvent: e,
                    eventHandler: () => setShowEditPopup(true),
                  })
                }
                tabIndex={0}
              >
                <EditIcon width={18} height={18} />
              </AttachmentsMediaOverlayEdit>
            )}
            {onDelete && (
              <AttachmentsMediaOverlayDelete
                onClick={onDelete}
                tabIndex={0}
                onKeyDown={(e: KeyboardEvent) =>
                  keyHandler({
                    keyEvent: e,
                    eventHandler: onDelete,
                  })
                }
              >
                <CloseIcon width={13} height={13} />
              </AttachmentsMediaOverlayDelete>
            )}
          </AttachmentsMediaOverlay>
          {showEditPopup && (
            <AttachmentsMediaEditPopup>
              <FocusLock>
                <AttachmentsMediaEditPopupHead>
                  <AttachmentsMediaEditPopupHeadTitle>
                    Edit Attachement
                  </AttachmentsMediaEditPopupHeadTitle>
                  <AttachmentsMediaEditPopupHeadCloseControls>
                    <AttachmentsMediaEditPopupHeadCloseIcon
                      onClick={closeEditPopup}
                      tabIndex={0}
                      onKeyDown={(e: KeyboardEvent) =>
                        keyHandler({
                          keyEvent: e,
                          eventHandler: closeEditPopup,
                        })
                      }
                    />
                    <CheckIcon
                      color={theme.colors.machineGreen600}
                      onClick={edit}
                      tabIndex={0}
                      onKeyDown={(e: KeyboardEvent) =>
                        keyHandler({
                          keyEvent: e,
                          eventHandler: edit,
                        })
                      }
                    />
                  </AttachmentsMediaEditPopupHeadCloseControls>
                </AttachmentsMediaEditPopupHead>
                <div>
                  <Input
                    placeholder="Add title here..."
                    value={title}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setTitle(e.target.value)
                    }
                  />
                  <AttachmentsMediaEditPopupTextArea
                    placeholder="Add description here..."
                    value={description}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      setDescription(e.target.value)
                    }
                  />
                </div>
              </FocusLock>
            </AttachmentsMediaEditPopup>
          )}
        </>
      )}
    </AttachmentsMediaStyled>
  );
};

export default AttachmentsMedia;
