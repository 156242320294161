import type { SVGAttributes } from 'react';

const SearchIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7549 11.2559H11.9649L11.6849 10.9859C12.6649 9.84586 13.2549 8.36586 13.2549 6.75586C13.2549 3.16586 10.3449 0.255859 6.75488 0.255859C3.16488 0.255859 0.254883 3.16586 0.254883 6.75586C0.254883 10.3459 3.16488 13.2559 6.75488 13.2559C8.36488 13.2559 9.84488 12.6659 10.9849 11.6859L11.2549 11.9659V12.7559L16.2549 17.7459L17.7449 16.2559L12.7549 11.2559ZM6.75488 11.2559C4.26488 11.2559 2.25488 9.24586 2.25488 6.75586C2.25488 4.26586 4.26488 2.25586 6.75488 2.25586C9.24488 2.25586 11.2549 4.26586 11.2549 6.75586C11.2549 9.24586 9.24488 11.2559 6.75488 11.2559Z"
      fill="currentColor"
    />
  </svg>
);

export default SearchIcon;
