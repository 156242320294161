import {
  TextareaHTMLAttributes,
  FocusEvent,
  ForwardRefRenderFunction,
  useId,
  useState,
  useCallback,
  forwardRef,
} from 'react';
import Error from '../Error';

import {
  TextAreaLabel,
  TextAreaStyled,
  TextAreaWrapper,
} from './TextArea.styles';
import { HelperTextProp } from '../Label/Label';

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Textarea label.
   */
  label?: string;
  helperText?: HelperTextProp;
  /**
   * Textarea disabled state. Readonly, not clickable.
   */
  disabled?: boolean;
  /**
   * To render resize button on text area corner.
   */
  canResize?: boolean;
  error?: string;
  /**
   * TextArea on focus callback.
   */
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  /**
   * TextArea on blur callback.
   */
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
}

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  props,
  ref,
) => {
  const [focused, setFocused] = useState<boolean>(false);
  const id = useId();

  const {
    label,
    disabled,
    canResize,
    error,
    onFocus,
    onBlur,
    helperText,
    className,
    ...otherProps
  } = props;

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  return (
    <TextAreaWrapper
      $active={focused}
      disabled={disabled}
      $error={!!error}
      className={className}
    >
      {label && (
        <TextAreaLabel
          htmlFor={id}
          data-testid="textarea-label"
          helperText={helperText}
          error={!!error}
        >
          {label}
        </TextAreaLabel>
      )}
      <TextAreaStyled
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid="textarea"
        ref={ref}
        disabled={disabled}
        $canResize={canResize}
        id={id}
        {...otherProps}
      />
      {error && <Error errorMessage={error} inline />}
    </TextAreaWrapper>
  );
};

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(TextArea);
