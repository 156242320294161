import styled, { css } from 'styled-components';
import Label from '../Label';
import { BodyStandard } from '../Typography/Typography.styles';

interface TextAreaStyledProps {
  $canResize?: boolean;
  $active?: boolean;
  disabled?: boolean;
  $error?: boolean;
}

export const TextAreaStyled = styled.textarea<TextAreaStyledProps>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
  width: 100%;
  min-height: 120px;
  outline: none;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray400};
  ${BodyStandard};
  padding: 15px;
  box-sizing: border-box;
  transition: border 0.2s ease;
  resize: ${(props) => (props.$canResize ? 'auto' : 'none')};
  &:disabled {
    border: inherit;
    background: inherit;
  }
`;

export const TextAreaLabel = styled(Label)``;

const TextAreaActiveStyles = css`
  ${TextAreaLabel} {
    color: ${(props) => props.theme.colors.black};
  }
  ${TextAreaStyled} {
    border: 1px solid ${(props) => props.theme.colors.black};
  }
`;

const TextAreaErrorStyles = css`
  ${TextAreaLabel} {
    color: ${(props) => props.theme.colors.alertRed};
  }
  ${TextAreaStyled} {
    border: 1px solid ${(props) => props.theme.colors.alertRed};
  }
`;

export const TextAreaWrapper = styled.div<TextAreaStyledProps>`
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  ${(props) => props.$active && TextAreaActiveStyles};
  ${(props) => props.$error && TextAreaErrorStyles}
`;
