import type { SVGAttributes } from 'react';

const PlusIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 8.5H8V14.5H6V8.5H0V6.5H6V0.5H8V6.5H14V8.5Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusIcon;
