import { ForwardRefRenderFunction, forwardRef } from 'react';
import BaseInput, { InputProps } from './BaseInput';
import PasswordInput from './PasswordInput';
import SearchInput from './SearchInput';

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref,
) => {
  const { type } = props;

  if (type === 'password') {
    return <PasswordInput {...props} ref={ref} />;
  }

  if (type === 'search') {
    return <SearchInput {...props} ref={ref} />;
  }

  return <BaseInput {...props} ref={ref} />;
};

export default forwardRef<HTMLInputElement, InputProps>(Input);
