import {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  useId,
  forwardRef,
  ButtonHTMLAttributes,
} from 'react';
import Error from '../Error';

// Styles
import {
  Container,
  InputErrorsLi,
  InputErrorsUl,
  IconContainer,
  InputLabel,
  BaseInput,
  InputWrapper,
} from './Input.styles';
import { HelperTextProp } from '../Label/Label';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Input text label.
   */
  label?: string;
  /**
   * Input disabled state. Readonly, not clickable.
   */
  disabled?: boolean;
  /**
   * Input error state.
   */
  error?: string;
  /**
   * List of input errors;
   */
  errors?: string[];
  /**
   * Props passed to the icons button
   */
  iconContainerProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  /**
   * Icon to show
   */
  icon?: React.ReactNode;
  /**
   * Helper text to include in a tooltip
   */
  helperText?: HelperTextProp;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref,
) => {
  const {
    label,
    className,
    iconContainerProps,
    icon,
    disabled = false,
    error,
    errors = [],
    helperText,
    ...inputProps
  } = props;

  const id = useId();

  return (
    <Container
      $disabled={disabled}
      $error={!!error}
      className={className}
      data-testid="input-block"
    >
      {label && (
        <InputLabel
          error={!!error}
          disabled={disabled}
          htmlFor={id}
          helperText={helperText}
        >
          {label}
        </InputLabel>
      )}
      <InputWrapper className="Wreno_BaseInput--input-wrapper">
        <BaseInput
          $hasIcon={!!icon}
          id={id}
          disabled={disabled}
          ref={ref}
          {...inputProps}
        />
        {icon && (
          <IconContainer
            type="button"
            disabled={disabled}
            {...iconContainerProps}
          >
            {icon}
          </IconContainer>
        )}
      </InputWrapper>
      {error && <Error data-testid="input-error" inline errorMessage={error} />}
      {errors && errors.length > 0 && (
        <InputErrorsUl>
          {errors.map((error: string, index: number) => (
            <InputErrorsLi key={index}>{error}</InputErrorsLi>
          ))}
        </InputErrorsUl>
      )}
    </Container>
  );
};

export default forwardRef<HTMLInputElement, InputProps>(Input);
