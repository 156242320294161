import type { SVGAttributes } from 'react';

const EditIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    fill="none"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5192 2.65901L6.45614 9.09835C6.32371 9.239 6.24931 9.42977 6.24931 9.62868V12.6287C6.24931 13.0429 6.56548 13.3787 6.95549 13.3787H9.7802C9.96749 13.3787 10.1471 13.2997 10.2795 13.159L16.3426 6.71967C17.17 5.84099 17.17 4.41637 16.3426 3.53769L15.5153 2.65901C14.6879 1.78033 13.3466 1.78033 12.5192 2.65901ZM15.342 4.59818L15.4007 4.66883C15.6161 4.96305 15.5965 5.38847 15.342 5.65884L9.48446 11.8785H7.6597V9.93901L13.5159 3.7195C13.7917 3.4266 14.2389 3.4266 14.5146 3.7195L15.342 4.59818ZM9.08353 3.62853C9.08353 3.21432 8.76736 2.87853 8.37735 2.87853H5.53089L5.37772 2.882C3.49872 2.96717 2 4.61197 2 6.62853V14.1285L2.00326 14.2912C2.08346 16.2868 3.63215 17.8785 5.53089 17.8785H12.5927L12.7458 17.8751C14.6248 17.7899 16.1235 16.1451 16.1235 14.1285V10.4398L16.1188 10.3523C16.078 9.97933 15.7795 9.6898 15.4174 9.6898C15.0274 9.6898 14.7112 10.0256 14.7112 10.4398V14.1285L14.7076 14.2607C14.6432 15.4418 13.7209 16.3785 12.5927 16.3785H5.53089L5.40641 16.3747C4.29431 16.3063 3.41235 15.3268 3.41235 14.1285V6.62853L3.41595 6.49633C3.48039 5.31522 4.40264 4.37853 5.53089 4.37853H8.37735L8.45971 4.37349C8.81092 4.33016 9.08353 4.01316 9.08353 3.62853Z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
