import type { SVGAttributes } from 'react';

const EyeCrossedIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.38 14.089l-1.145-1.146c.45-1.233.216-2.257-.704-3.073-.92-.816-1.918-1.024-2.995-.625l-1.145-1.146c.295-.19.625-.33.99-.417.364-.086.737-.13 1.12-.13 1.232 0 2.278.43 3.137 1.29.86.859 1.29 1.905 1.29 3.137 0 .382-.049.76-.144 1.133a3.44 3.44 0 01-.404.977zm3.36 3.36l-1.042-1.043a12.216 12.216 0 002.227-2.096c.633-.773 1.098-1.55 1.393-2.33-.868-1.928-2.17-3.451-3.907-4.571-1.736-1.12-3.62-1.68-5.65-1.68-.73 0-1.476.07-2.24.209-.764.139-1.363.303-1.797.494L7.526 5.208c.608-.277 1.385-.52 2.33-.729.947-.208 1.871-.312 2.774-.312 2.483 0 4.753.707 6.81 2.122 2.057 1.415 3.564 3.312 4.518 5.69a12.866 12.866 0 01-1.744 3.047 12.58 12.58 0 01-2.474 2.422zm1.51 5.884l-4.375-4.296c-.608.243-1.293.43-2.057.56a13.77 13.77 0 01-2.318.195c-2.535 0-4.835-.708-6.901-2.123-2.066-1.415-3.585-3.311-4.557-5.69.347-.902.829-1.784 1.445-2.643.616-.86 1.367-1.68 2.253-2.46L1.458 3.593l1.094-1.12 19.714 19.714-1.016 1.146zM5.807 7.97c-.642.469-1.263 1.085-1.862 1.849-.599.764-1.028 1.484-1.289 2.161.886 1.927 2.218 3.45 3.998 4.57 1.78 1.12 3.797 1.68 6.054 1.68.573 0 1.138-.034 1.693-.104.556-.07.972-.173 1.25-.312l-1.666-1.667a2.727 2.727 0 01-.704.195 5.075 5.075 0 01-.78.065c-1.216 0-2.258-.425-3.126-1.276-.868-.85-1.302-1.9-1.302-3.15 0-.261.022-.521.065-.782.043-.26.109-.495.195-.703L5.807 7.969z"
      fill="currentColor"
    />
  </svg>
);

export default EyeCrossedIcon;
