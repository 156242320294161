import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AttachmentType } from '../components/Attachments';

export interface FileResponse {
  // randomly generated UUID
  id: string;
  // path used in firebase storage
  storageLocation: string;
  // publicly accessible path to image
  filePath: string;
  // the original file name (mainly for rendering)
  originalFileName: string;
  // the attachments type
  type: AttachmentType;
}

function useFileUpload() {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [progress, setProgress] = useState(0);

  const getFileType = useCallback((file: File): AttachmentType => {
    if (file.type.includes('image')) {
      return AttachmentType.PHOTO;
    } else if (file.type.includes('video')) {
      return AttachmentType.VIDEO;
    } else if (file.type.includes('audio')) {
      return AttachmentType.AUDIO;
    }
    return AttachmentType.UNKNOWN;
  }, []);

  const deleteFromStorage = async (url: string, basePath?: string) => {
    try {
      let filePath = url.slice(
        decodeURIComponent(url).indexOf(basePath || 'files'),
      );
      const indexOfEndPath = filePath?.indexOf('?');
      filePath = filePath?.substring(0, indexOfEndPath);
      filePath = decodeURIComponent(filePath);
      const storage = getStorage();
      const storageRef = ref(storage, filePath);
      return await deleteObject(storageRef);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const upload = useCallback(
    (file: File, basePath?: string): Promise<FileResponse> => {
      const id = uuidv4();
      const [ext, ...rest] = (file.name || '')?.split('.')?.reverse() || [];
      const nameWithoutExtension = rest?.reverse()?.join('.') || '';
      const fileExtension = ext || '';
      const fileName = `${id}.${fileExtension}`;
      // get base storage from firebase
      const storage = getStorage();
      // create a new storage file (bucket)
      const storageLocation = `${basePath || 'files'}/${fileName}`;
      const storageRef = ref(storage, storageLocation);
      // create a write stream
      return new Promise((resolve, reject) => {
        const updatedFile = new File([file], fileName, {
          type: file.type,
          lastModified: file.lastModified,
        });
        const uploadTask = uploadBytesResumable(storageRef, updatedFile);
        setUploading(true);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            );
            setProgress(progress);
          },
          (error) => {
            // eslint-disable-next-line
            console.error('User upload failed', error);
            setError(error);
            reject(error);
          },
          async () => {
            const filePath = await getDownloadURL(uploadTask.snapshot.ref);
            setUploading(false);
            resolve({
              id,
              storageLocation,
              filePath,
              originalFileName: nameWithoutExtension,
              type: getFileType(file),
            });
          },
        );
      });
    },
    [getFileType],
  );

  return {
    upload,
    deleteFromStorage,
    uploading,
    error,
    progress,
  };
}
export default useFileUpload;
